import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Tag,
  Text
} from '@chakra-ui/react'
import InfoTip from 'components/Infotip'
import { isEmpty } from 'lodash'
import React from 'react'
import { getChartDataSummary } from '../../hooks/useManageDashboardEditor/helpers'

const ChartSummary = ({
  settings,
  source,
  aggregationType,
  queryMethod,
  context,
  EditButton = null,
  DeleteButton = null
}) => {
  if (isEmpty(settings)) {
    if (source) {
      return (
        <Text fontSize="sm">
          -&nbsp; All Business <b>{source?.label + "'s "}</b>
          {!queryMethod ? '' : 'without forms'}{` `}
          {aggregationType?.preview_text}{` `}
          <br />
        </Text>
      )
    }
    return <Text>Select a source to display the chart summary</Text>
  }
  return (
    <Stack>
      <Heading size="xs">
        Summary{' '}
        <InfoTip label="The chart will display the data based on all selected conditions" />
      </Heading>
      <Flex gap={2}>
        <Text fontSize="sm">
          {context?.label} {!queryMethod ? '' : 'without forms'} {aggregationType?.preview_text}{' '}
          <br />
        </Text>
        <Tag textTransform="uppercase" colorScheme="green">
          and
        </Tag>
      </Flex>
      <Flex align="center" flexWrap="wrap">
        <Text pl='10px' fontSize="sm">
          <b>{source?.label + "'s "}</b>
          {` `}having
        </Text>
        {settings.map((form, index) => {
          return (
            <Flex pl='0px' key={index} align="center" >
              <Menu>
                <MenuButton
                  variant="ghost"
                  as={Button}
                  size="sm"
                  rightIcon={<ChevronDownIcon />}
                  fontWeight='normal'
                >
                  {getChartDataSummary({ ...(form ?? {}), source, aggregationType, queryMethod, context })}
                </MenuButton>
                <MenuList>
                  {EditButton && EditButton(index)}
                  {DeleteButton && DeleteButton(index)}
                </MenuList>
              </Menu>
              {index < settings?.length - 1 && (
                <Tag textTransform="uppercase" colorScheme="green">
                  and
                </Tag>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Stack>
  )
}

export default ChartSummary
