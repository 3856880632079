import { Text } from '@chakra-ui/react'
import { camelCase, first, isEmpty, map, omit, pick } from 'lodash'
import React from 'react'
import { v4 as uuid } from 'uuid'

const validContexts = [
  'selectedFolder',
  'selectedTemplate',
  'selectedQuestion',
  'selectedQuestionOption',
  'selectedAssetTemplate',
  'selectedAssetTemplateField',
  'selectedAssetTemplateFieldOption'
]

export const getFirstValueToDefault = (array) => {
  if (array?.length === 1) {
    return first(array)
  }

  return null
}

export const formatDataToDefaultValues = (initialData, enumerators) => {
  const { id, attributes } = initialData.data

  const extractItemFromEnumerator = (itemValue, enumeratorId) =>
    enumerators?.[enumeratorId]?.find(({ value }) => value === itemValue)

  const extractItemFromSubList = (itemValue, list) =>
    list.find(({ value }) => value === itemValue)

  const contexts = map(enumerators?.dashboard?.contexts, (context) => context)
  const selectedContext = extractItemFromSubList(
    attributes.settings.context,
    contexts
  )

  return omit(
    {
      id,
      ...attributes,
      layout: extractItemFromEnumerator(attributes.layout, 'chart_layouts'),
      settings: {
        ...attributes.settings,
        context: selectedContext,
        filter: extractItemFromSubList(
          attributes.settings.filter,
          selectedContext?.filters ?? []
        )
      },
      charts_attributes: (attributes?.charts?.data ?? []).map(
        ({ attributes: chart }) => {
          const source = extractItemFromSubList(
            chart.source,
            selectedContext?.chart_sources ?? []
          )
          return {
            ...chart,
            _id: uuid(),
            source,
            aggregation_type: extractItemFromSubList(
              chart?.settings?.aggregation_type,
              source?.aggregation_types ?? []
            ),
            filter_type: extractItemFromSubList(
              chart.filterType,
              enumerators?.chart?.filter_types ?? []
            ),
            query_method: chart?.settings?.query_method === 'where_not',
            chart_type: extractItemFromSubList(
              chart.chartType,
              enumerators?.chart?.types
            ),
            settings: (chart?.settings?.query ?? []).map((chartItem, idx) => {
              const association = extractItemFromSubList(
                chartItem?.model,
                source?.query_associations ?? []
              )
              const field = extractItemFromSubList(
                chartItem?.field,
                association?.fields ?? []
              )
              const operator = extractItemFromSubList(
                chartItem?.operator,
                field?.operators ?? []
              )

              const getValueMatchContext = () => {
                if (chart?.settings?.frontend)
                  return chart?.settings?.frontend[idx]?.match_value_context
                return chartItem?.match?.match_value_context || {}
              }

              const getMatchValue = () => {
                if (chart?.settings?.frontend)
                  return chart?.settings?.frontend[idx]?.summary_text
                return chartItem?.match?.summary_text || chartItem?.match?.value
              }

              const getMatchOption = () => {
                if (chart?.settings?.frontend)
                  return chart?.settings?.frontend[idx]?.match_option
                return chartItem?.match?.match_option || null
              }

              return {
                ...chartItem,
                _id: uuid(),
                association: extractItemFromSubList(
                  chartItem?.model,
                  source?.query_associations ?? []
                ),
                operator,
                field,
                matchOption: getMatchOption(),
                matchValueContext: pick(
                  Object.entries(getValueMatchContext()).reduce(
                    (acc, [k, v]) => {
                      acc[camelCase(k)] = v
                      return acc
                    },
                    {}
                  ),
                  validContexts
                ),
                matchValue: {
                  label: getMatchValue(),
                  value: chartItem?.match?.value
                }
              }
            })
          }
        }
      )
    },
    ['businessId', 'charts']
  )
}

export const formatDataToSubmit = (data, removedItems = []) => {
  return {
    ...data,
    layout: data?.layout?.value,
    settings: {
      context: data?.settings?.context?.value,
      filter: data?.settings?.filter?.value
    },
    charts_attributes: [
      ...data.charts_attributes.map((attributes, index) => {
        return omit(
          {
            ...attributes,
            order: index + 1,
            chartType: attributes?.chart_type?.value,
            filterType: attributes?.filter_type?.value,
            source: attributes?.source?.value,
            settings: {
              frontend: attributes?.settings?.map((setting) => {
                return {
                  summary_text: setting?.matchValue?.label,
                  match_value_context: pick(
                    Object.entries(setting?.matchValueContext ?? {}).reduce(
                      (acc, [k, v]) => {
                        acc[k] = pick(v, [
                          'value',
                          'template_question_options',
                          'label',
                          'text',
                          'id'
                        ])
                        return acc
                      },
                      {}
                    ),
                    validContexts
                  ),
                  matchOption: setting?.matchOption
                }
              }),
              query_method: attributes?.query_method ? 'where_not' : 'where',
              aggregation_type: attributes?.aggregation_type?.value,
              query: attributes?.settings?.map((setting) => {
                return {
                  model: setting?.association?.value,
                  field: setting?.field?.value,
                  operator: setting?.operator?.value,
                  match: {
                    type: 'association_value',
                    ...(setting?.matchOption?.label === 'Question Option' ? {
                      template_id: setting?.matchValueContext?.selectedTemplate?.value
                    } : {}),
                    value: ['ilike'].includes(setting?.operator?.value)
                      ? setting?.matchValue?.label
                      : setting?.matchValue?.value
                  }
                }
              })
            }
          },
          [
            '_id',
            'chart_type',
            'filter_type',
            'aggregation_type',
            'query_method'
          ]
        )
      }),
      ...removedItems
    ]
  }
}

export const getChartDataSummary = (data) => {
  const { association, operator, matchValue, field, matchValueContext, matchOption } = data

  return (
    <Text>
      <b>{association?.preview_text ?? association?.label ?? '?'}</b>
      {` `}
      {field?.preview_text ?? field?.label ?? '?'}
      {` `}
      {operator?.preview_text ?? operator?.label ?? '?'}
      {` `}
      <b>
        {`"${
          matchValue?.preview_text ??
          matchValue?.label ??
          matchValue?.text ??
          '?'
        }"` ?? '-'}
      </b>
      {(matchOption?.label === 'Question Option' && !isEmpty(matchValueContext?.selectedTemplate)) ? (
        <>{' '} from template <b>{matchValueContext?.selectedTemplate?.label}</b></>
      ) : ''}
    </Text>
  )
}
